import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { string, bool, object } from "prop-types";

import ReportSnapshotHeader from "./ReportSnapshotHeader";
import ReportSnapshotBody from "./ReportSnapshotBody";
import ProgressIndicator from "../../ProgressIndicator";
import { connectToState } from "../../../redux/connect-api";
import { analysesLoadingSelector, reportSnapshotSelector } from "../../../selectors";
import { trackPageView } from "../../../hooks/usePageView";
import { userIsTrialSubscriberSelector } from "../../../selectors/userProfile"
import { isDataSuppressibleForTrial } from "../../../config";
import DataAccessAlert from "../../DataAccessAlert";

const getTitleAndYearFromReport = ({ attributes }) => {
  const currentCondition = attributes.specific_conditions[0] || "";
  let year = null;
  if (attributes.date_of_review) {
    year = new Date(attributes.date_of_review).getFullYear();
  }
  return { title: currentCondition, year };
};

const ReportSnapshot = (props) => {
  const { report = {}, analysesLoading, subscriptionIsTrial } = props;

  useEffect(() => {
    if (!report || !report.attributes) return;
    const { title, year } = getTitleAndYearFromReport(report);
    trackPageView(`Report Snapshot: ${title} (${year})`);
  }, [report]);

  if (analysesLoading) {
    return <ProgressIndicator active={analysesLoading} />;
  }

  // If report was not loaded or found indicate in UI
  if (!report) {
    return (
      <Box mt={4} width="100%">
        <Box mx={3}>
          <Alert severity="warning">Report Snapshot could not be found</Alert>
        </Box>
      </Box>
    );
  }

  const { attributes = null } = report || {};

  // If user does not have access to report
  const dateOfReview = new Date(attributes?.date_of_review)
  if (dateOfReview && subscriptionIsTrial && isDataSuppressibleForTrial(dateOfReview)) {
    return (
      <Box mt={4} width="100%">
        <Box mx={3}>
          <DataAccessAlert label="This report snapshot is" />
        </Box>
      </Box>
    )
  }

  let relatedDrugs = attributes.drugs || [];
  const currentCondition = attributes.specific_conditions[0] || "";
  let date = null;
  if (attributes.date_of_review) {
    date = new Date(attributes.date_of_review);
  }
  return (
    <>
      <ReportSnapshotHeader drugs={relatedDrugs} condition={currentCondition} date={date} />
      <ReportSnapshotBody report={attributes} />
    </>
  );
};

ReportSnapshot.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  activeReportId: string,
  report: object,
  analysesLoading: bool.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    report: reportSnapshotSelector(state, ownProps),
    analysesLoading: analysesLoadingSelector(state, ownProps),
    subscriptionIsTrial: userIsTrialSubscriberSelector(state)
  };
};

export default connectToState(mapStateToProps)(ReportSnapshot);
