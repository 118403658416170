import React from 'react';
import { TableRow, Tooltip, Skeleton, Typography, Stack } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import OldAnalysisIcon from '@mui/icons-material/AssignmentLate';
import { NavLink, withRouter } from 'react-router-dom';


import AnalysesTableDataCell from './AnalysesTableDataCell';
import RowSelector from './RowSelector';
import PriceInput, { PRICE_INPUT_BLUR_EVENT } from './PriceInput';
import PriceComparison from './PriceComparison';

import { fieldConfig, comparisonValuesKeys, isDataSuppressibleForTrial } from '../../config';
import formatValue from '../../utils/formatters';
import { getAnalysisRowGroup } from '../../utils/dom-helpers';

const drugsWithCustomTooltips = {
  Relyvrio: `
    Note: The Phase 3 Phoenix trial failed to confirm any clinical benefit of AMX0035
    (Relyvrio) for the treatment of ALS. On April 4, 2024, the manufacturer announced they
    would be withdrawing AMX0035 from the market.
  `,
  Lagevrio: `
    Note: The characteristics of the COVID-19 landscape have changed dramatically since this
    assessment was completed in May 2022
    `
}

const styles = () => ({
  outDatedRow: {
    '& th, & td': {
      color: '#717171',
      verticalAlign: 'middle !important', // Overriding style from stylesheet
    },
    '& td.population_and_comparator > div, & td.annual_dosage_assumptions > div': {
      minHeight: 'auto',
      '& > div': {
        lineClamp: '1 !important',
        '-webkit-line-clamp': '1 !important',
      },
      '& svg': {
        fill: '#949494'
      }
    },
    '& th.trade_name > div > a, & th.trade_name > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    '& a': {
      color: '#717171',
    }
  }
});

class AnalysesTableDataSubRow extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { focused: false };

    this.rowRef = React.createRef();

    this.cellRefs = {}; // delegate td click event to child components

    // inside constructor, to evaluate once:
    this.priceRowEvents = {
      onClick: (e) => {
        this.delegateCellClick(e, 'user_proposed_price');
        this.setState({ focused: true });
      }
    };

    // inside constructor, to evaluate once:
    this.mapCellEvents = {
      add_to_compare: {
        onClick: (e) => this.delegateCellClick(e, 'add_to_compare')
      },
      remove_from_compare: {
        onClick: (e) => this.delegateCellClick(e, 'remove_from_compare')
      }
    };

    this.isSuppressed = false
    if (this.props.suppressContent) {
      this.isSuppressed =
        this.props.row?.date_of_review ? isDataSuppressibleForTrial(this.props.row?.date_of_review) : false
    }
  }

  componentDidMount = () => {
    this.rowDom = this.rowRef.current;
    if (this.rowDom) {
      this.rowDom.addEventListener(PRICE_INPUT_BLUR_EVENT, () => {
        this.setState({ focused: false });
      });
    }
  }

  getCellRef = (slug, cellKey) => this.cellRefs[`${slug}-${cellKey}`]

  setCellRef = (slug, cellKey) => {
    this.cellRefs[`${slug}-${cellKey}`] = React.createRef();
    return this.getCellRef(slug, cellKey);
  }

  delegateCellClick = (e, fieldKey) => {
    // delegate cell click:
    const { id: slug } = getAnalysisRowGroup(e.target);
    const cellRef = this.getCellRef(slug, fieldKey);
    if (cellRef) {
      cellRef.current.handleClick(e);
    }
  };

  getRowHandlers = (rowKey) => {
    const { row: { hasIcerPrice } } = this.props;
    if (rowKey === 'user_proposed_price_row' && hasIcerPrice) {
      return this.priceRowEvents;
    }
    return {};
  }

  getCellHandlers = (fieldKey) => this.mapCellEvents[fieldKey] || {}

  buildValueForKey = ({fieldKey, config, isSuppressed}) => {
    if (!config) {
      return `(no config: ${fieldKey})`;
    }
    const { row } = this.props;
    const { slug } = row
    switch (fieldKey) {
      case 'remove_from_compare':
      case 'add_to_compare': {
        return (
          <RowSelector
            analysisId={slug}
            label=""
            useRemoveIcon={fieldKey === 'remove_from_compare'}
            ref={this.setCellRef(slug, fieldKey)}
          />
        );
      }
      case 'trade_name': {
        const { details_enabled, asset_group_id, is_outdated_analysis, trade_name } = row;
        const { location: { search = '' } } = this.props;
        let tooltipText = drugsWithCustomTooltips[trade_name] ?? `
          Note: ICER has completed a more recent assessment of this drug and condition
        `
        const OldNote = () => (
          <Tooltip title={ tooltipText }>
            <OldAnalysisIcon htmlColor='#949494' />
          </Tooltip>
        )
        if (details_enabled && asset_group_id) {
          return (
            <NavLink
              to={{
                pathname: `/report-snapshots/${asset_group_id}`,
                search
              }}
            >
              {row[fieldKey]}
              {is_outdated_analysis && <OldNote />}
            </NavLink>
          );
        }
        return <>
          {row[fieldKey]}
          {is_outdated_analysis && <OldNote />}
        </>
      }
      case 'user_proposed_price':
        if (isSuppressed)  {
          return <Skeleton variant="rectangular" animation={false} />
        }
        return (
          <PriceInput
            disabled={!row.hasIcerPrice}
            analysisId={slug}
            ref={this.setCellRef(slug, fieldKey)}
          />
        );
      default: {
        if (isSuppressed)  {
          if (fieldKey === "population_and_comparator")
            return <Stack flex={1}>
              <Typography variant="body1"><Skeleton width="100%" animation={false} /></Typography>
            </Stack>
          else {
            return <Typography variant="body1"><Skeleton width="100%" animation={false} /></Typography>
          }
        }
        if (fieldKey.match(/^manufacturer_price_vs/)) {
          const { benchmarkKey, comparisonKey } = config
          // To match the database columns we need to remove '_price'
          // If we do not include '_price' in the config established
          // in this file ('manufacturer' vs 'manufacturer_price'):
          // app/javascript/client/config/index.js | Lines: 113, 456, 522
          // these benchmark price fields will clash with the manufacturer name
          // field which has the same base key of 'manufacturer'
          const _comparisonKey = comparisonKey.replace('_price', '')
          let value = row[`${_comparisonKey}_${benchmarkKey}`]
          // If `value` is not null convert to Number so it's formatted correctly
          // null values are handled with the formatValue ('N/A' is returned)
          value = value ? Number(value) : value
          return formatValue(fieldKey, value, {}, 'usd');
        }
        if (comparisonValuesKeys.includes(fieldKey)) {
          return (
            <PriceComparison
              analysisId={slug}
              fieldKey={fieldKey}
            />
          );
        }
        const data = {}
        const useValue = config.fixedValue ? config.fixedValue : row[fieldKey];
        if (row.alternative_im_output_attributes) {
          data.url = row.alternative_im_output_attributes.url
        }
        return formatValue(fieldKey, useValue, data);
      }
    }
  }

  renderCell = (row, cellProps) => {
    const {
      rowSpan, renderKey, fieldKey
    } = cellProps;
    const { analysis_tooltip: tooltip } = row
    const config = fieldConfig[fieldKey]
    const isSuppressed = this.isSuppressed && config.suppressible
    return (
      <AnalysesTableDataCell
        key={renderKey}
        rowSpan={rowSpan}
        fieldKey={fieldKey}
        tooltip={!isSuppressed && fieldKey === "trade_name" && tooltip && <div>{tooltip}</div>}
        isSuppressed={isSuppressed}
        {...this.getCellHandlers(fieldKey)}
      >
        <div>{this.buildValueForKey({fieldKey, config, isSuppressed})}</div>
      </AnalysesTableDataCell>
    );
  }

  render() {
    const {
      row,
      row: { hasIcerPrice, is_outdated_analysis=false },
      subRowProps,
      classes
    } = this.props;
    const { focused } = this.state;
    const { rowRenderKey, columnProps } = subRowProps;
    const hasIcerPriceClass = hasIcerPrice ? 'has-icer-price' : '';
    const isOutdated = is_outdated_analysis ? classes.outDatedRow : '';
    const focusedInputClass = focused ? 'focused' : '';
    if (rowRenderKey === 'manufacturer_price_row' && !row.hasManufacturersPrice) {
      return null
    }
    return (
      <TableRow
        key={rowRenderKey}
        ref={this.rowRef}
        {...this.getRowHandlers(rowRenderKey)}
        className={`${rowRenderKey} ${hasIcerPriceClass} ${focusedInputClass} ${isOutdated}`.trim()}
      >
        {columnProps.map((cellProps) => this.renderCell(row, cellProps))}
      </TableRow>
    );
  }
}


export default withStyles(styles)(withRouter(AnalysesTableDataSubRow));
